import "../stylesheets/index.css";
import "@hotwired/turbo-rails";
import {
  PasswordRevealElement,
  PasswordValidatorElement,
  OpenDialogElement,
} from "../elements";

PasswordRevealElement.register();
PasswordValidatorElement.register();
OpenDialogElement.register();
