/**
 * ApplicationElement is an abstract class to help us build custom elements
 *
 * Subclasses of ApplicationElement use a stimulus-like system to define refs and events
 * Based on these settings, the element will automatically run some setup when connected.
 */
export default class ApplicationElement extends HTMLElement {
  /**
   * The default tagName that will be used to register this elements
   */
  static tagName = "application-element";

  /**
   * An array of elements that will be referenced in the elements
   *
   * These refs are expected to match DOM-nodes following the pattern
   * `[data-tag-name-ref="refName"]`
   *
   * @type string[]
   */
  static refs = [];

  /**
   * An object with events that should be observed.
   *
   * For each event a listener will be added when the elements connects (and removed when it disconnects)
   *
   * @type {{ [eventName: string]: string | { method: string; ref?: string, options?: boolean | AddEventListenerOptions; immediate?: boolean } }}
   */
  static events = {};

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  /**
   * The found refs, based on the class' `static refs`
   *
   * @type { [name: string]: Element | null}
   */
  refs = {};

  connectedCallback() {
    this.#detectRefs();
    this.#bindEvents();
  }

  disconnectedCallback() {
    this.#unbindEvents();
  }

  #detectRefs() {
    this.refs = {};
    this.constructor.refs.forEach((refName) => {
      this.refs[refName] = this.querySelector(
        `[data-${this.constructor.tagName}-ref=${refName}]`,
      );
    });
  }

  #bindEvents() {
    Object.entries(this.constructor.events).forEach(([eventName, settings]) => {
      const { element, callback, options, immediate } =
        this.#normalizeEventSettings(settings);
      element.addEventListener(eventName, callback, options);
      if (immediate) callback.call();
    });
  }

  #unbindEvents() {
    Object.entries(this.constructor.events).forEach(([eventName, settings]) => {
      const { element, callback, options } =
        this.#normalizeEventSettings(settings);
      element.removeEventListener(eventName, callback, options);
    });
  }

  #normalizeEventSettings(settings) {
    settings = typeof settings === "string" ? { method: settings } : settings;
    settings.element ||= this.refs[settings["ref"]] || this;
    settings.immediate ||= false;
    settings.callback = this[settings.method].bind(this);

    return settings;
  }
}
