import ApplicationElement from "./application_element";

export default class OpenDialogElement extends ApplicationElement {
  static tagName = "open-dialog";
  static refs = ["dialog"];
  static events = {
    "turbo:before-stream-render": { method: "openModal", element: document },
  };

  openModal(event) {
    if (event.detail.newStream.target == "successful_user_creation") {
      this.refs.dialog.showModal();
      return;
    }
  }
}
